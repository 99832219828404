import React from 'react';
import { Box, Flex, Text, Skeleton } from '@chakra-ui/react';
import Users from '../../assets/users.png';
import Login from '../../assets/login.png';

const MetricCard = ({ title, value, loading, smallText }) => {
  const getIcon = () => {
    if (title === 'Total Users' || title === 'Total Sign-Ups') {
      return Users;
    } else if (title === 'Total Logins') {
      return Login;
    }
    return null;
  };

  const icon = getIcon();

  return (
    <Skeleton
      isLoaded={!loading}
      borderRadius="10px"
      fadeDuration={1}
      startColor="#F7FAFC"
      endColor="#EDF2F7"
      flex={['1 1 100%', '1 1 0']} // Changed to allow growth
      minWidth={['100%', '200px']} // Added minWidth for desktop
    >
      <Box
        height="100px"
        p="1.35rem .8rem"
        bg="#FFF"
        borderRadius="10px"
        border=".5px solid #8E8E9333"
        position="relative"
      >
        <Text
          color="#000000"
          fontWeight="500"
          fontSize={smallText ? '10px' : '14px'}
          mb="0.2rem"
        >
          {title}
        </Text>
        <Text
          color="#000000"
          fontWeight="600"
          fontSize={smallText ? '20px' : '24px'}
        >
          {value}
        </Text>
        {icon && (
          <Box position="absolute" right="10px" top="25px">
            <img src={icon} alt={`${title} Icon`} width="50px" />
          </Box>
        )}
      </Box>
    </Skeleton>
  );
};

const Metrics = ({ data, loading, smallText }) => {
  return (
    <Flex
      alignItems="center"
      gap="1rem"
      overflowX={['visible', 'scroll']}
      flexDirection={['column', 'row']}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {loading
        ? Array(3)
            .fill(0)
            .map((_, index) => (
              <MetricCard
                key={index}
                title=""
                value=""
                loading={true}
                smallText
              />
            ))
        : data?.map(({ title, value }) => (
            <MetricCard
              key={title}
              title={title}
              value={value}
              loading={false}
            />
          ))}
    </Flex>
  );
};

export default Metrics;
