import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  Stack,
  useToast,
  Flex,
  Textarea,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SendNotificationImageUploader from '../../../../components/imageUploader/notificationImageUploader';
import SearchableSelect from '../../../../components/customSearchableSelect/customSearchableSelect';

const SendEmail = ({ setTriggerClose, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [messageType, setMessageType] = useState('HTML');
  const [message, setMessage] = useState('');
  const [recipientType, setRecipientType] = useState('');
  const [recipient, setRecipient] = useState('');
  const [recipientSelection, setRecipientSelection] = useState('');
  const [manualRecipients, setManualRecipients] = useState([]);
  const [game, setGame] = useState('');
  const [team, setTeam] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [loadingi, setLoadingi] = useState(false);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');

  const toast = useToast();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axiosInstance.get('/users/gamers');
        setRecipientOptions(data.data.content);
      } catch (error) {
        toast({
          title: 'An Error Occurred',
          description: 'Failed to load users',
          status: 'error',
        });
      }
    };

    fetchUsers();
  }, [searchTerm]);

  const selectStyles = {
    fontWeight: 400,
    fontSize: '14px',
    _focus: { borderColor: '#E8E9EA', outline: 'none', boxShadow: 'none' },
    _hover: {
      borderColor: '#E8E9EA',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: '#3A86E20F',
    },
    _placeholder: { color: '#71717A', fontSize: '14px', fontWeight: '400' },
    border: '1px solid #E8E9EA',
    borderColor: '#E8E9EA',
    color: 'black',
    borderRadius: '6px',
  };

  const handleImagesUpload = (images) => {
    setImageURL(images[0]);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (messageType === 'IMAGE' && !imageURL) {
      toast({
        title: 'Image Required',
        description: 'Please upload an image for image type messages',
        status: 'error',
      });
      setLoading(false);
      return;
    }

    if (messageType === 'HTML' && !message) {
      toast({
        title: 'Message Required',
        description: 'Please enter a message for HTML type messages',
        status: 'error',
      });
      setLoading(false);
      return;
    }

    const payload = {
      title,
      type: messageType,
      messageImageUrl: messageType === 'IMAGE' ? imageURL : undefined,
      redirectUrl: messageType === 'IMAGE' ? redirectUrl : undefined,
      message: messageType === 'HTML' ? message : undefined,
      sendToAll:
        recipientType !== 'single' &&
        recipientSelection === 'manual' &&
        manualRecipients.includes('all'),
      ...(!manualRecipients.includes('all') && { gamers: getRecipientsList() }),
    };

    try {
      const { data } = await axiosInstance.post(
        '/email-notification/gamers/send',
        payload
      );
      toast({
        description: data?.message || 'Email Sent',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getRecipientsList = () => {
    if (recipientType === 'single') {
      return [recipient.username];
    }

    if (recipientType === 'multiple') {
      if (
        recipientSelection === 'manual' &&
        !manualRecipients.includes('all')
      ) {
        return manualRecipients.slice(0, 150).map((rec) => rec.username);
      }
      if (recipientSelection === 'game') {
        return [game];
      }
      if (recipientSelection === 'team') {
        return [team];
      }
    }

    return [];
  };
  console.log(manualRecipients);
  return (
    <>
      <form onSubmit={sendEmail}>
        <FormControl mb="16px">
          <FormLabel mb={-2} fontSize=".9rem" opacity=".7" color="#71717A">
            Email title
          </FormLabel>
          <CustomInput
            type="text"
            required
            placeholder="Enter title here"
            onChange={(e) => setTitle(e.target.value)}
            mb=".8rem"
            customPadding="13px"
            customBorder="1px solid #E8E9EA"
            customBorderRadius="6px"
          />
        </FormControl>

        <FormControl mb="24px">
          <FormLabel
            mb="8px"
            fontSize="14px"
            fontWeight="400"
            color="#71717A"
            opacity=".7"
          >
            Message Type
          </FormLabel>
          <RadioGroup onChange={setMessageType} value={messageType}>
            <Stack direction="row">
              <Radio value="HTML">
                <Text fontSize="14px" fontWeight="400" color="black" mr="48px">
                  HTML Message
                </Text>
              </Radio>
              <Radio value="IMAGE">
                <Text fontSize="14px" fontWeight="400" color="black">
                  Image Message
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        {messageType === 'HTML' ? (
          <FormControl mb="24px">
            <FormLabel mb={2} fontSize=".9rem" opacity=".7" color="#71717A">
              Message Content
            </FormLabel>
            <Textarea
              required
              placeholder="Enter HTML message"
              onChange={(e) => setMessage(e.target.value)}
              mb=".8rem"
              border="1px solid #E8E9EA"
              borderRadius="6px"
              rows={6}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setMessage((prev) => prev + '\n');
                }
              }}
            />
            {/* <ReactQuill
              style={{ height: '200px', marginBottom: '3.3rem' }}
              onChange={(e) => setMessage(e)}
            /> */}
          </FormControl>
        ) : (
          <>
            <FormControl mb="24px">
              <FormLabel
                transform="translateY(.5rem)"
                opacity=".7"
                fontSize=".9rem"
              >
                Image Attachment
              </FormLabel>
              <Flex mt="1rem" gap=".5rem">
                <SendNotificationImageUploader
                  onImagesUpload={handleImagesUpload}
                  setLoading={setLoadingi}
                  loading={loadingi}
                  max={1}
                  onChange
                />
              </Flex>
              <Text mt="0" fontWeight="400" fontSize="12px" color="#949CA9">
                Max upload size more than 500kb. Only files with extensions
                (.jpeg, .png, .jpg) are allowed
              </Text>
            </FormControl>
            <FormControl mb="24px">
              <FormLabel
                transform="translateY(.5rem)"
                opacity=".7"
                fontSize=".9rem"
              >
                Redirect URL
              </FormLabel>
              <CustomInput
                type="text"
                required
                placeholder="Enter URL"
                onChange={(e) => setRedirectUrl(e.target.value)}
                mb=".8rem"
                customPadding="13px"
                customBorder="1px solid #E8E9EA"
                customBorderRadius="6px"
              />
            </FormControl>
          </>
        )}

        <FormControl mb="24px">
          <FormLabel
            mb="8px"
            fontSize="14px"
            fontWeight="400"
            color="#71717A"
            opacity=".7"
          >
            Select Recipients
          </FormLabel>
          <RadioGroup onChange={setRecipientType} value={recipientType}>
            <Stack direction="row">
              <Radio value="single">
                <Text fontSize="14px" fontWeight="400" color="black" mr="48px">
                  Single User
                </Text>
              </Radio>
              <Radio value="multiple">
                <Text fontSize="14px" fontWeight="400" color="black">
                  Multiple Users
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        {recipientType === 'single' && (
          <FormControl mb=".8rem">
            <FormLabel mb="8px" fontSize=".9rem" opacity=".7" color="#71717A">
              Recipient
            </FormLabel>
            <SearchableSelect
              options={recipientOptions}
              placeholder="Select recipient"
              onChange={(e) => setRecipient(e.target.value)}
              value={recipient}
              styles={selectStyles}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </FormControl>
        )}

        {recipientType === 'multiple' && (
          <FormControl mb=".8rem">
            <FormLabel mb="8px" fontSize=".9rem" opacity=".7" color="#71717A">
              Recipients Selection
            </FormLabel>
            <Select
              placeholder="Select recipient type"
              onChange={(e) => setRecipientSelection(e.target.value)}
              sx={selectStyles}
            >
              <option value="manual">Manual selection</option>
              <option value="game">Select by game</option>
              <option value="team">Select by team</option>
            </Select>

            {recipientSelection === 'manual' && (
              <FormControl>
                <FormLabel
                  mb="8px"
                  fontSize=".9rem"
                  opacity=".7"
                  mt="1rem"
                  color="#71717A"
                >
                  Recipients
                </FormLabel>
                <SearchableSelect
                  options={[{ title: 'All Users', id: 0 }, ...recipientOptions]}
                  placeholder="Select recipients"
                  onChange={(selectedValues) =>
                    setManualRecipients(selectedValues.target.value)
                  }
                  value={manualRecipients}
                  styles={selectStyles}
                  isMulti={true}
                />
              </FormControl>
            )}

            {recipientType === 'multiple' &&
              recipientSelection === 'manual' && (
                <Text fontSize="12px" color="#949CA9">
                  You can only select up to 150 users.
                </Text>
              )}

            {recipientSelection === 'game' && (
              <FormControl mb=".8rem">
                <FormLabel
                  mb="8px"
                  mt="16px"
                  fontSize=".9rem"
                  opacity=".7"
                  color="#71717A"
                >
                  Game
                </FormLabel>
                <Select
                  placeholder="Select game"
                  onChange={(e) => setGame(e.target.value)}
                  sx={selectStyles}
                >
                  <option value="cod">COD</option>
                  <option value="freefire">Freefire</option>
                  <option value="pubg">Pubg</option>
                </Select>
              </FormControl>
            )}

            {recipientSelection === 'team' && (
              <FormControl mb=".8rem">
                <FormLabel
                  mt="16px"
                  fontSize=".9rem"
                  opacity=".7"
                  color="#71717A"
                >
                  Team
                </FormLabel>
                <CustomInput
                  type="text"
                  required
                  mt="0"
                  customColor="#000000"
                  placeholder="Select Team"
                  onChange={(e) => setTeam(e.target.value)}
                  mb=".8rem"
                  customPadding="13px"
                  customBorder="1px solid #E8E9EA"
                  customBorderRadius="6px"
                />
              </FormControl>
            )}
          </FormControl>
        )}

        <PrimaryButton
          mt="2rem"
          text="Send email"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default SendEmail;
