import React, { useState } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
  Text,
} from '@chakra-ui/react';
import axiosInstance2 from '../../../../services/api2';
import ImageUploader from '../../../../components/imageUploader/imageUploader';

const UpdatePrice = ({ setTriggerClose, setRefetch, data }) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(data?.settingsModel?.nairaPrice);
  const [dollarPrice, setDollarPrice] = useState(
    data?.settingsModel?.dollarPrice
  );
  const [discountPercent, setDiscountPercent] = useState(
    data?.settingsModel?.discountPercent
  );
  const [checkedItems, setCheckedItems] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [imageURL2, setImageURL2] = useState('');
  const [imageURL3, setImageURL3] = useState('');
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);

  const toast = useToast();
  console.log(data);

  const handleDiscountPercentChange = (e) => {
    setDiscountPercent(parseFloat(e.target.value));
  };

  const handleImagesUpload = (images) => {
    setImageURL(images[0]);
  };

  const updateVoucherPrice = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      newNairaPrice: parseFloat(price),
      newDollarPrice: parseFloat(dollarPrice),
      discountPercent: parseFloat(discountPercent),
      type: data?.type,
      inStock: data?.settingsModel?.inStock ? true : false,
    };

    try {
      const { data } = await axiosInstance2.put('/gift-card/settings', datax);
      toast({
        description: data?.message || 'Price Created',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscountedPrice = () => {
    const discount = parseFloat(discountPercent) || 0;
    const newPrice = parseFloat(price) - (parseFloat(price) * discount) / 100;
    return newPrice.toFixed(2);
  };

  return (
    <>
      <form onSubmit={updateVoucherPrice}>
        <Text
          whiteSpace={'nowrap'}
          fontWeight={'500'}
          fontSize={'1.05rem'}
          color={'#27272A'}
        >
          Price ₦
        </Text>
        <CustomInput
          type={'int'}
          required
          placeholder={'Naira Price'}
          isReadOnly
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          mb=".8rem"
          style={{ cursor: 'not-allowed' }}
        />
        <Text
          whiteSpace={'nowrap'}
          fontWeight={'500'}
          fontSize={'1.05rem'}
          color={'#27272A'}
        >
          Price $
        </Text>
        <CustomInput
          type={'int'}
          required
          placeholder={'Dollar Price'}
          value={dollarPrice}
          onChange={(e) => setDollarPrice(e.target.value)}
          mb=".8rem"
        />
        <Text
          whiteSpace={'nowrap'}
          fontWeight={'500'}
          fontSize={'1.05rem'}
          color={'#27272A'}
        >
          Percentage %
        </Text>
        <CustomInput
          type={'number'}
          placeholder={'Discount Percentage %'}
          value={discountPercent === 0 ? 0 : discountPercent}
          onChange={handleDiscountPercentChange}
          mb=".8rem"
        />
        {discountPercent > 0 && (
          <Text fontStyle="italic" color="gray.600" mt=".5rem">
            The new discounted price is <b>₦{calculateDiscountedPrice()}</b>
          </Text>
        )}
        <PrimaryButton
          mt="2rem"
          text="Set Price"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default UpdatePrice;
