import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import axiosInstance3 from '../../../../services/unAuth';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import EmptyState from '../../../../components/emptyState/emptyState';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import LineSkeleton from '../../../../components/lineSkeleton';
import { buildResourceURL, truncateText } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Metrics from '../../../../components/metrics/metrics';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import { formatAmount } from '../../../../utils/helper';
import CreatePrice from './setPrice';
import UpdatePrice from './updateprice';
import UploadGiftCard from './uploadGiftCard';
import CustomSelect from '../../../../components/customSelect/customSelect';
import DownloadButton from '../../../../components/downloadTemplateButton';
import CreatePorteshopGame from '../games/createGames';
import CreateGiftCardType from './createGiftCardType';
import CreateGiftCard from './createGiftCard';
import GiftCardCreationFlow from './giftCardCreationFlow';
import MultiPhaseDrawer from './giftCardCreationFlow';
import UploadGiftCard2 from './uploadGiftCard2';

const GiftCards = ({ clientId }) => {
  const [giftCards, setGiftCards] = useState([]);
  const [totalGiftCards, setTotalGiftCards] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState('');
  const [status, setStatus] = useState('');
  const [consoles, setConsoles] = useState([]);
  const [consolex, setConsolex] = useState('');
  const [date, setDate] = useState('');
  const [triggerClose, setTriggerClose] = useState('');
  const [filterText, setFilterText] = useState(''); // Add state for filter text
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [seletedVoucher, setSelectdVoucher] = useState('');
  const [inStock, setInstock] = useState('');
  const [stockType, setStockType] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  const [newDollarPrice, setNewDollarPrice] = useState(0);
  const [discount, setNewDiscount] = useState(0);
  const [opendeliveryModal, setOpendeliveryModal] = useState(false);
  const [currentDeliveryType, setCurrentdeliveryType] = useState('');
  const [currentType, setCurrentType] = useState('');
  const [countries, setCountries] = useState([]);
  const [giftCardsType, setGiftCardsType] = useState([]);
  const [totalGiftCardType, setTotalGiftCardsType] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  // Get all vouchers
  const getVoucher = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/gift-card');
      const formattedData = data?.data.flatMap((game) =>
        game.availableGiftCards.map((voucher) => ({
          gameName: game?.game?.name,
          ...voucher,
        }))
      );
      setGiftCards(formattedData);
      setTotalGiftCards(formattedData?.length);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVoucher();
  }, [refetch]);

  const getGiftCardType = async () => {
    setLoading(true);
    try {
      if (filterText === '') {
        const { data } = await axiosInstance2.get('/gift-card-type');
        setGiftCardsType(data?.data?.content);
        setTotalGiftCardsType(data?.data?.content?.length || 0);
      } else {
        const { data } = await axiosInstance2.get(
          `/gift-card-type/${filterText}`
        );
        setGiftCardsType(data?.data?.content);
        setTotalGiftCardsType(data?.data?.content?.length || 0);
      }
    } catch (error) {
      toast({
        description: 'Failed to load Gift Card Types',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getCountry = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/country');
      const formmattedCountry = data?.data?.map((country) => ({
        title: country?.name,
        value: country?.code,
      }));
      setCountries(formmattedCountry);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGiftCardType();
  }, [refetch, filterText]);
  useEffect(() => {
    getCountry();
  }, [refetch]);
  const countryOptions = [{ title: 'All Countries', value: '' }, ...countries];

  const handleModal = (type, status, nairaPrice, dollarPrice, discount) => {
    setInstock(status);
    setStockType(type);
    setNewPrice(nairaPrice);
    setNewDollarPrice(dollarPrice);
    setNewDiscount(discount);
    onOpen();
  };

  const handleDelivery = (type, deliveryType) => {
    setOpendeliveryModal(true);
    setCurrentType(type);
    setCurrentdeliveryType(deliveryType);
  };

  const handleDeliveryChange = async () => {
    setLoadingx(true);
    const request = {
      deliveryType: currentDeliveryType,
    };
    try {
      const { data } = await axiosInstance2.patch(
        `gift-card/${currentType}/update`,
        request
      );
      toast({
        description: data?.message || 'Successful',
        status: 'success',
        position: 'top',
      });
      setOpendeliveryModal(false);
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoadingx(false);
      setOpendeliveryModal(false);
    }
  };

  const handleStock = async () => {
    setLoading(true);
    const datax = {
      type: stockType,
      inStock: inStock,
      newNairaPrice: newPrice,
      newDollarPrice: newDollarPrice,
      discountPercent: discount,
    };
    try {
      const { data } = await axiosInstance2.put(`/gift-card/settings`, datax);
      toast({
        description: data?.message || 'Successful',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  // Calculate metrics with conditional checks
  // 1. Total number of different types of vouchers
  const totalVoucherTypes = giftCards?.length ?? 0;

  // 2. Total price of all vouchers in stock
  const totalPriceInStock =
    giftCards
      ?.filter((voucher) => voucher?.settingsModel)
      ?.reduce(
        (acc, voucher) =>
          acc +
          (voucher?.settingsModel?.nairaPrice ?? 0) * (voucher?.count ?? 0),
        0
      ) ?? 0;

  // 3. Average discount percentage for vouchers in stock
  const vouchersInStock =
    giftCards?.filter((voucher) => voucher?.settingsModel?.inStock) ?? [];
  const totalDiscountPercent =
    vouchersInStock?.reduce(
      (acc, voucher) => acc + (voucher?.settingsModel?.discountPercent ?? 0),
      0
    ) ?? 0;
  const averageDiscountPercent = vouchersInStock?.length
    ? totalDiscountPercent / vouchersInStock.length
    : 0;

  // 5. Highest and lowest priced vouchers
  const filterValidPrices = (item) =>
    item?.settingsModel !== null && item?.settingsModel?.nairaPrice > 0;

  // Filter valid items
  const validItems = giftCards?.filter(filterValidPrices);

  // Use Math.max and Math.min to find highest and lowest prices
  const highestPrice = Math.max(
    ...validItems?.map((item) => item?.settingsModel?.nairaPrice)
  );
  const lowestPrice = Math.min(
    ...validItems?.map((item) => item?.settingsModel?.nairaPrice)
  );

  // 6. Total count of vouchers with a discount
  const totalVouchersWithDiscount =
    giftCards
      ?.filter((voucher) => voucher?.settingsModel?.discountPercent > 0)
      ?.reduce(
        (acc, voucher) =>
          acc + (voucher?.settingsModel?.discountPercent > 0 ?? 0),
        0
      ) ?? 0;

  const voucherData = [
    {
      title: 'Total Product Type',
      value: !loading ? formatAmount(totalGiftCards) : null,
    },
    {
      title: 'Total Card Count',
      value: !loading
        ? formatAmount(
            giftCards?.reduce((acc, voucher) => acc + voucher?.count, 0)
          )
        : null,
    },
    {
      title: 'Total Price of Card',
      value: !loading ? `₦${formatAmount(totalPriceInStock)}` : null,
    },
    {
      title: 'Highest Priced Card',
      value: !loading ? `₦${formatAmount(highestPrice)}` : null,
    },
    {
      title: 'Lowest Priced Card',
      value: !loading ? `₦${formatAmount(lowestPrice)}` : null,
    },
    {
      title: 'Cards with Discount',
      value: !loading ? formatAmount(totalVouchersWithDiscount) : null,
    },
  ];

  const productOptions = [
    {
      title: 'All Product',
      value: '',
    },
    ...Array.from(new Set(giftCards?.map((i) => i?.gameName)))
      .filter(Boolean)
      .map((gameName) => ({
        title: gameName,
        value: gameName,
      })),
  ];

  // Filter vouchers based on filter text
  const filteredVouchers = giftCards.filter((voucher) =>
    voucher?.gameName?.toLowerCase().includes(filterText.toLowerCase())
  );
  console.log(currentDeliveryType);
  return (
    <>
      <Metrics data={voucherData} loading={loading} smallText />
      <Box
        bg="#FFF"
        mt=".7rem"
        p="1.5rem 1rem"
        borderRadius={'12px'}
        overflowX="auto"
        whiteSpace="nowrap"
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Gift Cards{' '}
            <span
              style={{
                padding: '.1rem .3rem',
                borderRadius: '50%',
                backgroundColor: '#F9FAFB',
                border: '1px solid #EAECF0',
                fontSize: '12px',
                fontWeight: '500',
                color: '#344054',
              }}
            >
              {totalVoucherTypes}
            </span>
          </Text>
          <Box
            mt="1rem"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CustomSelect
              required
              options={productOptions}
              onChange={(e) => setFilterText(e.target.value)}
              height="33px !important"
              mt="0"
              mr="1rem"
              borderRadius={'8px'}
              width="120px"
              cursor="pointer"
              fontSize=".85rem"
            />

            <Box>
              {/* <GiftCardCreationFlow countries={countries}/> */}
              <MultiPhaseDrawer
                setRefetch={setRefetch}
                consoles={consoles}
                countries={countries}
              />
            </Box>
            <CustomDrawer
              position={'right'}
              title={'Upload Gift Card'}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <SecondaryButton
                  type="submit"
                  text={'Upload Gift Card'}
                  icon="add"
                  loading={false}
                  mr="1rem"
                />
              }
              content={
                <UploadGiftCard2
                  setTriggerClose={setTriggerClose}
                  setRefetch={setRefetch}
                  countries={countries}
                  max={1}
                />
              }
            />
            <DownloadButton
              fileUrl={`${process.env.PUBLIC_URL}/Playstation.xlsx`}
              fileName="Playstation.xlsx"
              buttonText="Download Template"
            />
          </Box>
        </Flex>

        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Prod Name
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                NAME
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                TYPE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                COUNT
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                $ PRICE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                ₦ PRICE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                DISCT %
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                DISCT PRICE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                IN STOCK
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                OUT OF STOCK
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : filteredVouchers?.length < 1 ? (
              <EmptyState mt="1.5rem" text="No Gift Cards available" />
            ) : (
              filteredVouchers.map(
                (
                  { type, name, count, settingsModel, gameName, deliveryType },
                  i
                ) => {
                  const data = { type, name, settingsModel, deliveryType };
                  return (
                    <Tr key={i}>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {gameName}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {name}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {deliveryType}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {count}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel
                          ? `$${formatAmount(settingsModel?.dollarPrice)}`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel
                          ? `₦${formatAmount(settingsModel?.nairaPrice)}`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel
                          ? `${formatAmount(settingsModel?.discountPercent)}%`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel
                          ? `₦${formatAmount(
                              (
                                settingsModel?.nairaPrice -
                                (settingsModel?.nairaPrice *
                                  settingsModel.discountPercent) /
                                  100
                              ).toFixed(2)
                            ).replace(/\.00$/, '')}`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel
                          ? settingsModel?.inStock
                            ? 'Yes'
                            : 'No'
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {settingsModel?.inStock === true ? (
                          <PrimaryButton
                            text="Set out of stock"
                            width="100px"
                            backgroundColor="#dc143c"
                            padding=".5rem !important"
                            fontSize=".8rem !important"
                            onClick={() =>
                              handleModal(
                                type,
                                false,
                                settingsModel?.nairaPrice,
                                settingsModel?.dollarPrice,
                                settingsModel?.discountPercent
                              )
                            }
                          />
                        ) : (
                          <PrimaryButton
                            text="Set in-stock"
                            width="100px"
                            padding=".5rem !important"
                            fontSize=".8rem !important"
                            onClick={() =>
                              handleModal(
                                type,
                                true,
                                settingsModel?.nairaPrice,
                                settingsModel?.dollarPrice,
                                settingsModel?.discountPercent
                              )
                            }
                          />
                        )}
                      </Td>
                      <Td>
                        <Menu width={'200px !important'}>
                          <MenuButton>
                            <BiDotsVerticalRounded
                              style={{ cursor: 'pointer' }}
                            />
                          </MenuButton>
                          <MenuList>
                            <Divider my=".2rem" />
                            {!settingsModel ? (
                              <MenuItem
                                // onClick={}
                                fontWeight={'500'}
                                fontSize={'.85rem'}
                              >
                                <CustomDrawer
                                  position={'right'}
                                  title={'Set Price'}
                                  triggerClose={triggerClose}
                                  setTriggerClose={setTriggerClose}
                                  toggleElement={
                                    <Text
                                      textDecoration={'underline'}
                                      mb=".2rem"
                                      color={'#101828'}
                                      fontSize={'.9rem'}
                                      fontWeight={'500'}
                                      textTransform={'capitalize'}
                                    >
                                      Set Price
                                    </Text>
                                  }
                                  content={
                                    <CreatePrice
                                      data={data}
                                      setTriggerClose={setTriggerClose}
                                      setRefetch={setRefetch}
                                      consoles={consoles}
                                    />
                                  }
                                />
                              </MenuItem>
                            ) : (
                              <>
                                <Divider my=".2rem" />
                                <MenuItem
                                  // onClick={}
                                  fontWeight={'500'}
                                  fontSize={'.85rem'}
                                >
                                  <CustomDrawer
                                    position={'right'}
                                    title={'Update Price & Discount'}
                                    triggerClose={triggerClose}
                                    setTriggerClose={setTriggerClose}
                                    toggleElement={
                                      <Text
                                        textDecoration={'underline'}
                                        mb=".2rem"
                                        color={'#101828'}
                                        fontSize={'.9rem'}
                                        fontWeight={'500'}
                                        textTransform={'capitalize'}
                                      >
                                        Update Price or discount
                                      </Text>
                                    }
                                    content={
                                      <UpdatePrice
                                        data={data}
                                        setTriggerClose={setTriggerClose}
                                        setRefetch={setRefetch}
                                        consoles={consoles}
                                      />
                                    }
                                  />
                                </MenuItem>
                              </>
                            )}
                            <MenuItem
                              // onClick={}
                              fontWeight={'500'}
                              fontSize={'.85rem'}
                            >
                              <Text
                                textDecoration={'underline'}
                                mb=".2rem"
                                color={'#101828'}
                                fontSize={'.9rem'}
                                fontWeight={'500'}
                                textTransform={'capitalize'}
                                onClick={() =>
                                  handleDelivery(type, deliveryType)
                                }
                              >
                                Update Delivery Type
                              </Text>
                            </MenuItem>
                            <Divider my=".2rem" />
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                }
              )
            )}
          </Tbody>
        </Table>

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
              Stock Management
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <Text textAlign={'center'} color={'#475467'} fontWeight={'500'}>
                  Confirm you want to declare {stockType}{' '}
                  {inStock === false ? 'out of stock' : 'in stock'}
                </Text>
                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={handleStock}
                    text="Confirm"
                    width="150px"
                    bg={
                      inStock === false
                        ? '#dc143c !important'
                        : '#228B22 !important'
                    }
                    padding="1rem !important"
                    loading={loading}
                  />
                </Flex>
              </>
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* delivery Modal */}
        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={opendeliveryModal}
          onClose={() => setOpendeliveryModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={'1rem'}
              textTransform={'capitalize'}
              textAlign={'center'}
            >
              Change Delivery Type
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <CustomSelect
                  required
                  options={[
                    { title: 'Digital', value: 'DIGITAL' },
                    { title: 'WhatsApp', value: 'WHATSAPP' },
                  ]}
                  onChange={(e) => setCurrentdeliveryType(e.target.value)}
                  height="33px !important"
                  my="1rem"
                  mx="auto"
                  borderRadius={'8px'}
                  width="70%"
                  cursor="pointer"
                  fontSize=".85rem"
                  value={currentDeliveryType}
                />
              </>
              <PrimaryButton
                onClick={handleDeliveryChange}
                text="Confirm"
                width="70%"
                display="block"
                bg={'#228B22 !important'}
                padding="1rem !important"
                loading={loadingx}
                mx="auto"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default GiftCards;
