import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from '@chakra-ui/react';
import axiosInstance2 from '../../../../services/api2';
import axiosInstance from '../../../../services/api'; // Added import for the rate endpoint
import ImageUploader from '../../../../components/imageUploader/imageUploader';

const CreatePrice = ({ setTriggerClose, setRefetch, data }) => {
  const [loading, setLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [dollarPrice, setDollarPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [rate, setRate] = useState(0);

  const toast = useToast();

  // Fetch exchange rate on component mount
  useEffect(() => {
    getRates();
  }, []);

  // Function to fetch exchange rate
  const getRates = async () => {
    setRateLoading(true);
    try {
      const { data } = await axiosInstance.get('/system-settings');
      const exchangeRate = data?.data?.nairaToDollarRate;
      setRate(exchangeRate);

      // If there's existing data, calculate the price
      if (data?.voucherPrice?.dollarPrice) {
        calculateNairaPrice(data.voucherPrice.dollarPrice, exchangeRate);
      }

      return data?.data;
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast({
        title: 'Error Fetching Exchange Rate',
        description:
          'Could not fetch the current exchange rate. Please try again.',
        status: 'error',
      });
      return null;
    } finally {
      setRateLoading(false);
    }
  };

  // Function to calculate Naira price based on Dollar price and rate
  const calculateNairaPrice = (dollarAmount, currentRate) => {
    const calculatedPrice = parseFloat(dollarAmount) * currentRate;
    setPrice(calculatedPrice.toFixed(2));
  };

  // Handler for dollar price input change
  const handleDollarPriceChange = (e) => {
    const newDollarPrice = e.target.value;
    setDollarPrice(newDollarPrice);
    calculateNairaPrice(newDollarPrice, rate);
  };

  const setVoucherPrice = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      newNairaPrice: parseFloat(price),
      newDollarPrice: parseFloat(dollarPrice),
      discountPercent: parseFloat(discountPercent),
      type: data?.type,
      inStock: data?.voucherPrice?.inStock ? true : false,
    };

    try {
      const { data } = await axiosInstance2.put('/gift-card/settings', datax);
      toast({
        description: data?.message || 'Price Created',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={setVoucherPrice}>
        <CustomInput
          type={'number'}
          step="0.01"
          required
          placeholder={'Naira Price (Calculated)'}
          value={price}
          isReadOnly={true}
          isDisabled={rateLoading}
          mb=".8rem"
          style={{ cursor: 'not-allowed' }}
        />
        <CustomInput
          type={'number'}
          step="0.01"
          required
          placeholder={
            rateLoading ? 'Loading exchange rate...' : 'Dollar Price'
          }
          value={dollarPrice}
          onChange={handleDollarPriceChange}
          isDisabled={rateLoading}
          mb=".8rem"
        />
        <CustomInput
          type={'number'}
          step="0.01"
          required
          placeholder={'Discount Percentage %'}
          onChange={(e) => setDiscountPercent(e.target.value)}
          mb=".8rem"
        />
        {rate > 0 && (
          <Box fontSize="sm" color="gray.500" mb="1rem">
            Current exchange rate: 1 USD = {rate} NGN
          </Box>
        )}
        <PrimaryButton
          mt="2rem"
          text="Set Price"
          type="submit"
          loading={loading || rateLoading}
        />
      </form>
    </>
  );
};

export default CreatePrice;
