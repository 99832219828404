import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import axiosInstance3 from '../../../../services/unAuth';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import EmptyState from '../../../../components/emptyState/emptyState';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import LineSkeleton from '../../../../components/lineSkeleton';
import { buildResourceURL, truncateText } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Metrics from '../../../../components/metrics/metrics';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import { formatAmount } from '../../../../utils/helper';
import CreatePrice from './setPrice';
import UpdatePrice from './updateprice';
import UploadVoucher from './uploadVoucher';
import CustomSelect from '../../../../components/customSelect/customSelect';
import DownloadButton from '../../../../components/downloadTemplateButton';

const Voucher = ({ clientId }) => {
  const [vouchers, setVouchers] = useState([]);
  const [totalVouchers, setTotalVouchers] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState('');
  const [status, setStatus] = useState('');
  const [consoles, setConsoles] = useState([]);
  const [consolex, setConsolex] = useState('');
  const [date, setDate] = useState('');
  const [triggerClose, setTriggerClose] = useState('');
  const [filterText, setFilterText] = useState(''); // Add state for filter text
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [seletedVoucher, setSelectdVoucher] = useState('');
  const [inStock, setInstock] = useState('');
  const [stockType, setStockType] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  const [discount, setNewDiscount] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const getVoucher = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get(
        '/game-voucher/available-vouchers'
      );
      const formattedData = data?.data.flatMap((game) =>
        game.availableVouchers.map((voucher) => ({
          gameName: game.game.name,
          ...voucher,
        }))
      );
      setVouchers(formattedData);
      setTotalVouchers(formattedData?.length);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVoucher();
  }, [refetch]);

  const handleModal = (type, status, price, discount) => {
    setInstock(status);
    setStockType(type);
    setNewPrice(price);
    setNewDiscount(discount);
    onOpen();
  };

  const handleStock = async () => {
    setLoading(true);
    const datax = {
      type: stockType,
      inStock: inStock,
      newPrice,
      discountPercent: discount,
    };
    try {
      const { data } = await axiosInstance2.put(
        `/game-voucher/update-price`,
        datax
      );
      toast({
        description: data?.message || 'Successful',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  // Calculate metrics with conditional checks
  // 1. Total number of different types of vouchers
  const totalVoucherTypes = vouchers?.length ?? 0;

  // 2. Total price of all vouchers in stock
  const totalPriceInStock =
    vouchers
      ?.filter((voucher) => voucher?.voucherPrice)
      ?.reduce(
        (acc, voucher) =>
          acc + (voucher?.voucherPrice?.price ?? 0) * (voucher?.count ?? 0),
        0
      ) ?? 0;

  // 3. Average discount percentage for vouchers in stock
  const vouchersInStock =
    vouchers?.filter((voucher) => voucher?.voucherPrice?.inStock) ?? [];
  const totalDiscountPercent =
    vouchersInStock?.reduce(
      (acc, voucher) => acc + (voucher?.voucherPrice?.discountPercent ?? 0),
      0
    ) ?? 0;
  const averageDiscountPercent = vouchersInStock?.length
    ? totalDiscountPercent / vouchersInStock.length
    : 0;

  // 5. Highest and lowest priced vouchers
  const filterValidPrices = (item) =>
    item?.voucherPrice !== null && item?.voucherPrice?.price > 0;

  // Filter valid items
  const validItems = vouchers?.filter(filterValidPrices);

  // Use Math.max and Math.min to find highest and lowest prices
  const highestPrice = Math.max(
    0,
    ...validItems?.map((item) => item?.voucherPrice?.price)
  );

  const lowestPrice = Math.min(
    0,
    ...validItems?.map((item) => item?.voucherPrice?.price)
  );

  // 6. Total count of vouchers with a discount
  const totalVouchersWithDiscount =
    vouchers
      ?.filter((voucher) => voucher?.voucherPrice?.discountPercent > 0)
      ?.reduce(
        (acc, voucher) =>
          acc + (voucher?.voucherPrice?.discountPercent > 0 ?? 0),
        0
      ) ?? 0;

  const voucherData = [
    {
      title: 'Total Product Type',
      value: !loading ? formatAmount(totalVouchers) : null,
    },
    {
      title: 'Total Voucher Count',
      value: !loading
        ? formatAmount(
            vouchers?.reduce((acc, voucher) => acc + voucher?.count, 0)
          )
        : null,
    },

    {
      title: 'Total Price of vouchers',
      value: !loading ? `₦${formatAmount(totalPriceInStock)}` : null,
    },
    {
      title: 'Highest Priced Voucher',
      value: !loading ? `₦${formatAmount(highestPrice)}` : null,
    },
    {
      title: 'Lowest Priced Voucher',
      value: !loading ? `₦${formatAmount(lowestPrice)}` : null,
    },
    {
      title: 'Total Count of Vouchers with Discount',
      value: !loading ? formatAmount(totalVouchersWithDiscount) : null,
    },
  ];

  const productOptions = [
    {
      title: 'All Product',
      value: '',
    },
    ...Array.from(new Set(vouchers?.map((i) => i?.gameName)))
      .filter(Boolean)
      .map((gameName) => ({
        title: gameName,
        value: gameName,
      })),
  ];

  console.log(productOptions);
  // Filter vouchers based on filter text
  const filteredVouchers = vouchers.filter((voucher) =>
    voucher.gameName.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <Metrics data={voucherData} loading={loading} smallText />
      <Box
        bg="#FFF"
        mt=".7rem"
        p="1.5rem 1rem"
        borderRadius={'12px'}
        overflowX="auto"
        whiteSpace="nowrap"
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Vouchers{' '}
            <span
              style={{
                padding: '.1rem .3rem',
                borderRadius: '50%',
                backgroundColor: '#F9FAFB',
                border: '1px solid #EAECF0',
                fontSize: '12px',
                fontWeight: '500',
                color: '#344054',
              }}
            >
              {totalVoucherTypes}
            </span>
          </Text>
          <Box
            mt="1rem"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CustomSelect
              required
              options={productOptions}
              onChange={(e) => setFilterText(e.target.value)}
              height="33px !important"
              mt="0"
              mr="1rem"
              borderRadius={'8px'}
              width="120px"
              cursor="pointer"
              fontSize=".85rem"
            />

            <CustomDrawer
              position={'right'}
              title={'Upload Voucher'}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <SecondaryButton
                  type="submit"
                  text={'Upload Voucher'}
                  icon="add"
                  loading={false}
                  mr="1rem"
                />
              }
              content={
                <UploadVoucher
                  setTriggerClose={setTriggerClose}
                  setRefetch={setRefetch}
                  max={1}
                />
              }
            />
            <DownloadButton
              fileUrl={`${process.env.PUBLIC_URL}/free-fire.xlsx`}
              fileName="template.xlsx"
              buttonText="Download Template"
            />
          </Box>
        </Flex>

        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Prod Name
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                NAME
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                TYPE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                COUNT
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                PRICE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                DISCT %
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                DISCT PRICE
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                IN STOCK
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                OUT OF STOCK
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : filteredVouchers?.length < 1 ? (
              <EmptyState mt="1.5rem" text="No vouchers available" />
            ) : (
              filteredVouchers.map(
                ({ type, name, count, voucherPrice, gameName }, i) => {
                  const data = { type, name, voucherPrice };
                  return (
                    <Tr key={i}>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {gameName}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {name}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {type}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {count}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {voucherPrice
                          ? `₦${formatAmount(voucherPrice?.price)}`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {voucherPrice
                          ? `${formatAmount(voucherPrice?.discountPercent)}%`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {voucherPrice
                          ? `₦${formatAmount(
                              Math.round(
                                voucherPrice?.price -
                                  (voucherPrice?.price *
                                    voucherPrice.discountPercent) /
                                    100
                              )
                            )}`
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {voucherPrice
                          ? voucherPrice?.inStock
                            ? 'Yes'
                            : 'No'
                          : 'N/A'}
                      </Td>
                      <Td color={'#475467'} fontSize={'.7rem'}>
                        {voucherPrice?.inStock === true ? (
                          <PrimaryButton
                            text="Set out of stock"
                            width="100px"
                            backgroundColor="#dc143c"
                            padding=".5rem !important"
                            fontSize=".8rem !important"
                            onClick={() =>
                              handleModal(
                                type,
                                false,
                                voucherPrice?.price,
                                voucherPrice?.discountPercent
                              )
                            }
                          />
                        ) : (
                          <PrimaryButton
                            text="Set in-stock"
                            width="100px"
                            padding=".5rem !important"
                            fontSize=".8rem !important"
                            onClick={() =>
                              handleModal(
                                type,
                                true,
                                voucherPrice?.price,
                                voucherPrice?.discountPercent
                              )
                            }
                          />
                        )}
                      </Td>
                      <Td>
                        <Menu width={'200px !important'}>
                          <MenuButton>
                            <BiDotsVerticalRounded
                              style={{ cursor: 'pointer' }}
                            />
                          </MenuButton>
                          <MenuList>
                            <Divider my=".2rem" />
                            {!voucherPrice ? (
                              <MenuItem
                                // onClick={}
                                fontWeight={'500'}
                                fontSize={'.85rem'}
                              >
                                <CustomDrawer
                                  position={'right'}
                                  title={'Set Price'}
                                  triggerClose={triggerClose}
                                  setTriggerClose={setTriggerClose}
                                  toggleElement={
                                    <Text
                                      textDecoration={'underline'}
                                      mb=".2rem"
                                      color={'#101828'}
                                      fontSize={'.9rem'}
                                      fontWeight={'500'}
                                      textTransform={'capitalize'}
                                    >
                                      Set Price
                                    </Text>
                                  }
                                  content={
                                    <CreatePrice
                                      data={data}
                                      setTriggerClose={setTriggerClose}
                                      setRefetch={setRefetch}
                                      consoles={consoles}
                                    />
                                  }
                                />
                              </MenuItem>
                            ) : (
                              <>
                                <Divider my=".2rem" />
                                <MenuItem
                                  // onClick={}
                                  fontWeight={'500'}
                                  fontSize={'.85rem'}
                                >
                                  <CustomDrawer
                                    position={'right'}
                                    title={'Update Price & Discount'}
                                    triggerClose={triggerClose}
                                    setTriggerClose={setTriggerClose}
                                    toggleElement={
                                      <Text
                                        textDecoration={'underline'}
                                        mb=".2rem"
                                        color={'#101828'}
                                        fontSize={'.9rem'}
                                        fontWeight={'500'}
                                        textTransform={'capitalize'}
                                      >
                                        Update Price or discount
                                      </Text>
                                    }
                                    content={
                                      <UpdatePrice
                                        data={data}
                                        setTriggerClose={setTriggerClose}
                                        setRefetch={setRefetch}
                                        consoles={consoles}
                                      />
                                    }
                                  />
                                </MenuItem>
                              </>
                            )}

                            <Divider my=".2rem" />
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                }
              )
            )}
          </Tbody>
        </Table>

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
              Stock Management
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <Text textAlign={'center'} color={'#475467'} fontWeight={'500'}>
                  Confirm you want to declare {stockType}{' '}
                  {inStock === false ? 'out of stock' : 'in stock'}
                </Text>
                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={handleStock}
                    text="Confirm"
                    width="150px"
                    bg={
                      inStock === false
                        ? '#dc143c !important'
                        : '#228B22 !important'
                    }
                    padding="1rem !important"
                    loading={loading}
                  />
                </Flex>
              </>
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default Voucher;
